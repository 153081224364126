import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import VerticalImage from '../asset/Group 1.png';

const ThreeScene = () => {
  const [selectedOption, setSelectedOption] = useState("redevelopment");

  const content = {
    redevelopment: {
      text: [
        "• Members voice - Listening to members to the society and mark the aspirations and expectations",
        "• Documentation, Location and market analysis - Local laws on land and construction drive the possibility and feasibility of building the property to new life. It's based on development rules and other aspects of laws. We present the case alongside the possibilities and what can be achieved.",
        "• Terms and commercial framework - We structure a deal with a possible gain matrix and roadmap to dream house, with a primary design blueprint",
        "• Members consent",
        "• Agreement and fine points - A structured plan to be sanctioned is part of consent and agreement document with transparency, followed by final plan to sanction",
        "• Relocation and facilitation - Adhering to terms agreed, members are facilitated as a team and family to source a temporary rental accommodation",
        "• Plan to reality on ground - Ensure the quality and time aspect, bring to life the approved sanctioned plan.",
        "• Completion and handover - It's a blissful day and activity to bring Heart at Home, a reality",
      ],
      image: "https://res.cloudinary.com/dkcgwqdmm/image/upload/v1723229515/Group_40_2_fl4rmz.png"
    },
    commercial: {
      text: [
        "• Commercial - Members voice - Tailored solutions focusing on commercial aspirations.",
        "• Documentation and market analysis focusing on commercial potential.",
        "• Customized terms and commercial framework.",
        "• Commercial project consent and agreement.",
        "• Facilitation of temporary business relocation.",
        "• Plan to reality on ground for commercial entities.",
        "• Completion and handover focusing on commercial interests.",
      ],
      image: "https://res.cloudinary.com/dkcgwqdmm/image/upload/v1725087306/Group_51_fcnwwp.png" // Replace with actual image URL
    },
    industrial: {
      text: [
        "• Industrial - Members voice - Listening to the needs of industrial stakeholders.",
        "• Detailed industrial documentation and analysis.",
        "• Structured deal with a focus on industrial feasibility.",
        "• Consent and agreement for industrial projects.",
        "• Facilitation of temporary industrial relocation.",
        "• Plan to reality focusing on industrial requirements.",
        "• Completion and handover with industrial precision.",
      ],
      image: "https://res.cloudinary.com/dkcgwqdmm/image/upload/v1725087306/Group_51_fcnwwp.png" // Replace with actual image URL
    }
  };

  return (
    <div id="Analysis" className="min-h-screen w-full bg-white p-8 flex items-center justify-center">
      <div className="flex flex-col lg:grid lg:grid-cols-2 gap-8 w-full max-w-7xl mx-auto">
        
        {/* Heading */}
        <div className="col-span-2 text-left mb-8">
          <img src={VerticalImage} alt="" className="w-5 h-12 object-contain mb-4" />
          <h1 className="text-4xl lg:text-5xl font-bold text-orange-600 mb-4 text-left">Feasibility Analysis / How it Works</h1>
        </div>

        {/* Left Section - Content */}
        <div className="flex flex-col justify-center w-full lg:w-[65%] order-2 lg:order-none">
          <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-16 mb-4">
            {["redevelopment", "commercial", "industrial"].map(option => (
              <div key={option} className="relative inline-block hover:text-white">
                <button
                  className={`flex items-center justify-center py-3.5 px-8 w-full md:min-w-[200px] rounded-r-full shadow-md transition-all duration-300 ${
                    selectedOption === option
                      ? "bg-teal-700 text-white"
                      : "bg-[#FBF6E2] text-black"
                  } hover:bg-teal-700 hover:text-white`}
                  onClick={() => setSelectedOption(option)}
                >
                  <span className="text-md font-medium">{option.charAt(0).toUpperCase() + option.slice(1)}</span>
                </button>
                <div className="absolute -left-5 h-14 w-14 -top-0.5 bg-[#FBF6E2] text-[#131842] rounded-full p-2 flex items-center justify-center border-[0.2px] border-[#131842]">
                  <FontAwesomeIcon icon={faBuilding} size="lg" />
                </div>
              </div>
            ))}
          </div>

          <div className="mt-4 space-y-2">
            {content[selectedOption].text.map((item, index) => (
              <p key={index} className="text-gray-700">
                {item}
              </p>
            ))}
          </div>
        </div>

        {/* Right Section - Image */}
        <div className="flex justify-center items-center w-full lg:w-full order-1 lg:order-none lg:mt-40">
          <img
            src={content[selectedOption].image}
            alt={selectedOption}
            className="w-full h-auto max-w-2xl"
          />
        </div>
      </div>
    </div>
  );
};

export default ThreeScene;
