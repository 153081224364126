import React, { useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import image1 from '../asset/J-House-y0-Design-Architect-1_1521x1050.jpg';
import image2 from '../asset/moden-elevation-230-1400x788.jpg';
import image3 from '../asset/mezzanine.jpg';
import image4 from '../asset/interior-designers-in-pune.webp';
import image5 from '../asset/OfficeFurniture.jpg';
import image6 from '../asset/Sofa.png';
import image7 from '../asset/commercial-interior-designers1.jpg';
import HorizontalDivider from './HorizontalDivider';
import VerticalImage from '../asset/Group 1.png'

const AreasOfExpertise = () => {
  // Slider settings
  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
  };

  // State to track the selected section
  const [selectedSection, setSelectedSection] = useState('commercial');

  // Content data for each section
  const sectionData = {
    commercial: {
      title: "Commercial and Industrial Development",
      description: `We are the team that you are looking for that will bring in a team of dedicated enablers to build an industrial or commercial facility for you, from design to construct on site. Our sense and approach of entrepreneurship and technical expertise enable a seamless work. We will associate for location feasibility, built to suit activities and help navigate in the local eco-system.`,
    },
    residential: {
      title: "Residential Development",
      description: `Search ends with us to develop a dream house, redevelopment project or ensure a precision building you dream to construct.`,
    },
    educational: {
      title: "Educational Development and Startup Ecosystem",
      description: `As our strong focus area, we work closely with startups with a innovative mind and our team that understands it. Our mission to work strongly with startup, comes from passion of our founder, Vinay Malshe. He is actively engaging with startup eco-system on mentoring front. His experience in commercial, business strategy and financial prudence has a strong foot print with startups and entrepreneurs across.`,
    },
  };

  return (
    <div id='Product' className="min-h-screen w-full lg:h-auto p-8 mx-auto bg-[#FFF] mt-20">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-32 w-full max-w-7xl mx-auto">
        
        {/* Left Section - Text Content */}
        <div className="flex flex-col items-start">
          <img src={VerticalImage} alt="" className="w-5 h-12 object-contain mb-4" />
          <h1 className="text-4xl lg:text-5xl font-bold text-orange-600 text-left mb-4">
            Area<br /> Of Expertise
          </h1>
          <p className="text-gray-700 text-left text-lg w-full lg:w-[87%]">
            We build various dynamic and creative structures, professionally with a detailed approach. Realty
            and construction is a complex business and we ensure to hold the ground for you. Our expertise in
            managing the construction life cycle is seamless with our skills in conducting feasibility, designing,
            and development on the ground.
          </p>
        </div>

        {/* Right Section - Image Slider */}
        <div className="flex justify-center items-center w-full h-full">
          <Slider {...settings} className="w-full">
            <div className="flex justify-center">
              <img src={image1} alt="" className="w-full h-96 md:object-contain object-cover" />
            </div>
            <div className="flex justify-center">
              <img src={image2} alt="Expertise 2" className="w-full h-96 object-cover" />
            </div>
            <div className="flex justify-center">
              <img src={image3} alt="Expertise 3" className="w-full h-96 object-cover" />
            </div>
            <div className="flex justify-center">
              <img src={image4} alt="" className="w-full h-96 object-cover" />
            </div>
          </Slider>
        </div>
      </div>

      {/* Bottom Section - Image Grid */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-16 w-full max-w-7xl mx-auto">
      <div className="relative cursor-pointer group" onClick={() => setSelectedSection('commercial')}>
        <img src={image5} alt="Commercial and Industrial Development" className="w-full h-96 object-cover" />
          <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <h3 className="text-white text-center text-md lg:text-lg font-bold opacity-0 group-hover:opacity-100 transition-opacity duration-300">
              Commercial and Industrial Development
            </h3>
          </div>
      </div>
      <div className="relative cursor-pointer group" onClick={() => setSelectedSection('residential')}>
        <img src={image6} alt="Residential Development" className="w-full h-96 object-cover" />
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <h3 className="text-white text-center text-md lg:text-lg font-bold opacity-0 group-hover:opacity-100 transition-opacity duration-300">
            Residential Development
          </h3>
        </div>
      </div>
      <div className="relative cursor-pointer group" onClick={() => setSelectedSection('educational')}>
        <img src={image7} alt="Educational Development and Startup Ecosystem" className="w-full h-96 object-cover" />
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <h3 className="text-white text-center text-md lg:text-lg font-bold opacity-0 group-hover:opacity-100 transition-opacity duration-300">
            Educational Development and Startup Ecosystem
          </h3>
        </div>
      </div>

      </div>
      {/* Description below the grid */}
      {selectedSection && (
        <div className="mt-10 lg:mt-20 w-full max-w-7xl mx-auto">
          <h2 className="text-2xl font-bold text-gray-800">
            {sectionData[selectedSection].title}
          </h2>
          <p className="text-gray-700 text-lg mt-8 lg:w-[70%] w-full">
            {sectionData[selectedSection].description}
          </p>
        </div>
      )}

      {/* Horizontal Divider */}
      <div className="flex items-center mt-[10vh] lg:gap-32 w-full max-w-7xl mx-auto">
        <HorizontalDivider />
        <div className="flex-1 h-1 bg-[#E68369] ml-4"></div>
      </div>
    </div>
  );
};

export default AreasOfExpertise;
