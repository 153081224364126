import React from 'react';
import groupImage from '../asset/Group 23.png';
import groupImage2 from '../asset/Group 20.png';
import HorizontalDivider from './HorizontalDivider';

const Section = () => {
  return (
    <div className="bg-[#FBF6E2]" id='Goals'>
      <div className="flex flex-col items-center justify-center w-full h-auto lg:h-screen p-8 mx-auto">
        {/* Container for content */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-28 w-full max-w-7xl">
          {/* Left Content */}
          <div className="relative flex flex-col items-center lg:items-start mt-20">
            <img 
              src={groupImage} 
              alt="Group 23" 
              className="absolute w-8 h-8 lg:w-12 lg:h-12 left-8 md:-left-6 -top-4 md:-top-6" 
            />
            <div className="relative z-10">
              <h1 className="text-[#212A31] text-2xl lg:text-6xl font-semibold leading-tight">
              Vision and Mission
              </h1>
              <h2 className="text-[#212A31] text-2xl lg:ml-10 lg:text-6xl font-semibold leading-tight mt-4">
                <span className="font-bold">Comfort</span>
              </h2>
              <h2 className="text-[#212A31] text-2xl lg:ml-10 lg:text-6xl font-semibold leading-tight mt-4">
                <span className="font-bold">Energy</span>
              </h2>
              <h2 className="text-[#212A31] text-2xl lg:ml-10 lg:text-6xl font-semibold leading-tight mt-4">
                <span className="font-bold">Sustainability</span>
              </h2>
            </div>
            <img 
              src={groupImage2} 
              alt="Group 20" 
              className="absolute w-8 h-8 lg:w-12 lg:h-12 -bottom-5 lg:-bottom-5 right-10 lg:right-10" 
            />
          </div>
          {/* Right Content */}
          <div className="flex flex-col justify-center items-start mt-20">
            <div className="w-full lg:w-[90%]">
              <p className="text-left text-[#212A31] text-md lg:text-xl font-light mb-6 leading-relaxed">
                <span className="font-bold">Design -</span> Ensure to blend aesthetics, requirements of happy living and working with comfort and optimum use of space.
              </p>
              <p className="text-left text-[#212A31] text-md lg:text-xl font-light mb-6 leading-relaxed">
                <span className="font-bold">Develop -</span> Ensure seamless delivery with a plan on ground, to suit the requirements with happy stakeholders, 'AT ALL TIMES'.
              </p>
              <p className="text-left text-[#212A31] text-md lg:text-xl font-light leading-relaxed">
                <span className="font-bold">Deliver -</span> Precision with focusing with attention to details, compliant to norms and regulations and adhere to timelines and achieving creation with efficiency.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Horizontal Divider at the end of the section */}
      <div className="flex items-center mt-[10vh] gap-8 lg:gap-32 w-80 lg:w-full max-w-7xl mx-auto">
      <div className="flex-1 h-1 bg-[#E68369] lg:ml-4 mt-1"></div>
        <HorizontalDivider />
      </div>
    </div>
  );
}

export default Section;
