import React from 'react';
import groupLogo from '../asset/whitelogo.png';
import groupLinkdin from '../asset/Linkdin.png';
import groupFacebook from '../asset/Facebook.png';
import groupInstagram from '../asset/Instagram.png';

const Footer = () => {
  return (
    <div className="w-full bg-[#2C3531] py-6 flex flex-col lg:flex-row items-center justify-between">
      {/* Logo Section */}
      <div className="flex items-center justify-center lg:justify-start lg:pl-8 mb-4 lg:mb-0">
        <img src={groupLogo} alt="Group 20" className="h-12 w-auto" />
      </div>

      {/* Links Section */}
      <div className="flex flex-col lg:flex-row items-center justify-center lg:justify-center space-y-4 lg:space-y-0 lg:space-x-8 mb-4 lg:mb-0">
        <a href="#Product" className="text-white no-underline text-center">Area of Expertise</a>
        <a href="#Analysis" className="text-white no-underline text-center">Feasibility analysis</a>
        <a href="#AboutUs" className="text-white no-underline text-center">About us</a>
      </div>

      {/* Social Media Icons Section */}
      <div className="flex items-center justify-center lg:pr-8 space-x-4">
        <img src={groupLinkdin} alt="LinkedIn" className="h-6 w-auto" />
        <img src={groupFacebook} alt="Facebook" className="h-6 w-auto" />
        <img src={groupInstagram} alt="Instagram" className="h-6 w-auto" />
      </div>
    </div>
  );
}

export default Footer;
