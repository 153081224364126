import React, { useEffect, useState } from 'react';
import axios from 'axios';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReCAPTCHA from 'react-google-recaptcha'; // Import reCAPTCHA

const Contact = () => {
  const [selectedReason, setSelectedReason] = useState(null);
  const [items, setItems] = useState([]);
  const [captchaVerified, setCaptchaVerified] = useState(false); // Store the CAPTCHA response

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await axios.get('http://localhost:5000/items');
        setItems(response.data);
      } catch (error) {
        console.error('There was an error fetching the items!', error);
      }
    };

    fetchItems();
  }, []);

  const handleButtonClick = (reason) => {
    setSelectedReason(reason);
  };

  const handleCaptchaChange = (value) => {
    console.log("Captcha value:", value);
    setCaptchaVerified(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaVerified) {
      alert("Please complete the CAPTCHA");
      return;
    }

    const form = e.target;

    const templateParams = {
      first_name: form.firstName.value,
      last_name: form.lastName.value,
      email: form.email.value,
      reason: selectedReason,
      location: form.location.value,
      land_area: form.landArea.value,
    };

    emailjs
      .send('service_v7n8nyj', 'template_8hu8uds', templateParams, 'GBKEQStv-K1vxFSk1')
      .then((response) => {
        toast.success('Form submitted successfully!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        // Reset form fields and CAPTCHA
        form.reset();
        setSelectedReason(null); // Clear CAPTCHA response
      })
      .catch((error) => {
        toast.error('Form submission failed. Please try again.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.error('FAILED...', error);
      });
  };



  return (
    <div id='Contact' className="min-h-screen w-full p-8 mx-auto lg:mt-10">
      <ToastContainer />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-32 w-full max-w-7xl mx-auto">
        <div className="flex flex-col justify-center lg:items-start">
          <h1 className="text-6xl font-bold text-black mb-4 text-left">Reach out</h1>
          <p className="text-gray-700 mb-6 text-left">Get in touch with us for any questions</p>
          <form className="space-y-4 w-full" onSubmit={handleSubmit}>
            <div className="flex flex-col">
              <label className="text-gray-600 font-semibold">First name</label>
              <input
                type="text"
                name="firstName"
                className="p-2 border border-gray-300 rounded-md mt-4"
                placeholder=""
                required
              />
            </div>
            <div className="flex flex-col md:flex-row gap-5 mt-4">
              <div className="flex flex-col w-full">
                <label className="text-gray-600 font-semibold">Email address</label>
                <input
                  type="email"
                  name="email"
                  className="p-2 border border-gray-300 rounded-md mt-4"
                  placeholder=""
                  required
                />
              </div>
              <div className="flex flex-col w-full">
                <label className="text-gray-600 font-semibold">Last name</label>
                <input
                  type="text"
                  name="lastName"
                  className="p-2 border border-gray-300 rounded-md mt-4"
                  placeholder=""
                  required
                />
              </div>
            </div>
            <div className="flex flex-col mt-4">
              <label className="text-gray-600 font-semibold">Select reason for reaching out</label>
              <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                {["Open land", "Redevelopment", "Industrial", "Commercial"].map((reason) => (
                  <button
                    key={reason}
                    type="button"
                    onClick={() => handleButtonClick(reason)}
                    className={`p-2 border border-gray-300 rounded-md mt-4 font-semibold ${
                      selectedReason === reason ? "bg-orange-100" : "bg-white"
                    } text-xs md:text-base overflow-hidden text-ellipsis whitespace-nowrap`}
                  >
                    {reason}
                  </button>
                ))}
              </div>
            </div>
            <div className="flex flex-col mt-4">
              <label className="text-gray-600 font-semibold">Your location</label>
              <input
                type="text"
                name="location"
                className="p-2 border border-gray-300 rounded-md mt-4 font-medium"
                placeholder="Residential"
                required
              />
            </div>
            <div className="flex flex-col mt-4">
              <label className="text-gray-600 font-semibold">Land Area size</label>
              <input
                type="text"
                name="landArea"
                className="p-2 border border-gray-300 rounded-md mt-4 md:w-[30%] font-medium"
                placeholder="00000 sq.ft"
                required
              />
            </div>

            {/* reCAPTCHA widget */}
            <div className="flex justify-start my-4">
              <ReCAPTCHA
                sitekey="6LdzkDoqAAAAAD-AeOC4OwKq36kalwn6GqvUV47Z" // Replace with your own site key
                onChange={handleCaptchaChange}
              />
            </div>

            <button
              type="submit"
              className="bg-gray-400 text-white p-2 rounded-md w-full font-semibold"
            >
              Submit
            </button>
          </form>
        </div>

        {/* Right Section - Latest Updates */}
        <div className="flex flex-col justify-center lg:pl-8 w-full lg:w-[90%]">
          <div className="bg-yellow-100 p-6 rounded-md mb-8">
            <h2 className="text-xl font-bold text-gray-800 mb-4">Latest Updates</h2>
            {items.length > 0 ? (
              <div>
                {items.map((item) => (
                  <div key={item._id} className="mb-4">
                    <h3 className="text-lg font-medium text-gray-800">{item.title}</h3>
                    <a href={item.link} className="text-blue-500" target="_blank" rel="noopener noreferrer">
                      {item.link}
                    </a>
                    {item.imageUrl && (
                      <img
                        src={`http://localhost:5000${item.imageUrl}`}
                        alt={item.title}
                        className="mt-2 rounded"
                        style={{ maxWidth: '100%' }}
                      />
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-gray-700">No updates available.</p>
            )}
          </div>
          <div className="flex justify-between mb-8">
            <div className="flex space-x-4">
              <span className="text-black cursor-pointer font-bold">Linkedin</span>
              <span className="text-black cursor-pointer font-bold">Instagram</span>
              <span className="text-black cursor-pointer font-bold">Facebook</span>
            </div>
          </div>
          <div className="text-gray-700">
            <h3 className="font-bold">We are located at -</h3>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3763.189424540261!2d73.80323481555257!3d18.50356938741062!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bdf31c02e221%3A0x45f4d9b2d347cc71!2sGoogle!5e0!3m2!1sen!2sin!4v1632972876465!5m2!1sen!2sin"
              width="100%"
              height="200"
              allowFullScreen=""
              loading="lazy"
              title="Location of our company on Google Maps"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
