import React from 'react';
import Building from '../asset/building.png';
import HorizontalDivider from './HorizontalDivider'; // Import the HorizontalDivider component

const Infrastructure = () => {
  return (
    <div id='AboutUs' className="w-full bg-[#FBF6E2]">
      <div className="flex justify-center items-center mt-[10vh] gap-8 lg:gap-32 w-80 lg:w-full max-w-7xl mx-auto">
      <div className="flex-1 h-1 bg-[#E68369] lg:ml-4 "></div>
        <HorizontalDivider />
      </div>
      {/* Full height wrapper with flexbox to center content */}
      <div className="flex items-center justify-center w-full h-full p-8 mx-auto">
        <div className="flex flex-col items-center justify-center w-full h-auto lg:h-full lg:my-20 mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-32 w-full max-w-7xl">
            {/* Image Section */}
            <img
              src={Building}
              alt="About Us"
              className="w-full h-auto object-cover mb-4 lg:mb-0 lg:h-full flex justify-center items-center"
            />

            {/* Text Section */}
            <div className="flex flex-col justify-center w-full lg:w-[100%] lg:pl-8">
              <h1 className="lg:text-4xl text-2xl font-bold text-blue-900 mb-4">Malshe Infrastructure</h1>
              <div className='flex flex-col gap-y-5'>
              <p className="text-gray-700 text-lg lg:mt-10 mb-4">
                Team comes with collective experience of 30+ years in business and project management, design and development.
                Our strong belief to be a leader and market competitive construction company, makes us unique. We thrive
                continuously to ensure deliver quality with unmatched benchmarks, attention to needs of our customers, with
                detail and transparency in our processes and operations, consistently.
              </p>
              <p className="text-gray-700 text-lg mb-4">
                With a strong understanding of construction engineering and aesthetic designing skills are core, we endeavor to
                compete with customer and market expectations in search of excellence.
              </p>
              <p className="text-gray-700 text-lg">
                This journey we have undertaken, leads the way with core values of ‘integrity’ and ‘creativity’. ‘Design, Develop
                and Deliver’ are 3 pillars and work as a backbone to our business practices and processes. It keeps us focused
                on customer requirements quality and efficiency.
              </p>
              </div>
            </div>
          </div>

          {/* Second Grid: Key Values */}
          <div className="flex justify-center w-full max-w-7xl mt-8 lg:mt-28">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 w-full">
              <div className="text-left w-full lg:w-[75%]">
                <h3 className="text-3xl font-bold text-gray-700 mb-4">Sustainability</h3>
                <p className="text-gray-600 lg:mt-10">
                  Balancing by reducing the impact of carbon emissions by reducing the impact with synergizing the processes
                  with care. We believe using sustainable technologies and materials to possible extent is a beginning in world
                  of green life!
                </p>
              </div>
              <div className="text-left w-full lg:w-[75%]">
                <h3 className="text-3xl font-bold text-gray-700 mb-4">Ease of living</h3>
                <p className="text-gray-600 lg:mt-10">
                  Human and cultural factor in distinguishing home to house, we believe in bringing the user needs to optimize
                  the facilitation for daily life! Catering to psychological aspects of human mind as to the idea of home
                  synchronizing comfort with usage with space utilization is one of the key goals!
                </p>
              </div>
              <div className="text-left w-full lg:w-[80%]">
                <h3 className="text-3xl font-bold text-gray-700 mb-4">People specific design</h3>
                <p className="text-gray-600 lg:mt-10">
                  A healthy competition of demand and catering to it, we thrive to work hard with focus on the market needs
                  and user aspirations with sense of exclusivity.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Horizontal Divider at the end of the section */}
      <div className="flex items-center mt-[10vh] lg:gap-32 w-[85%] md:w-full max-w-7xl mx-auto">
        <HorizontalDivider />
        <div className="flex-1 h-1 bg-[#E68369] ml-4  lg:mt-1"></div>
      </div>
    </div>
  );
};

export default Infrastructure;
