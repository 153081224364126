import React from 'react';
import Section from './components/AboutUs';
import Footer from './components/Footer';
import Contact from './components/Contact';
import Section5 from './components/MalsheInfo'; // Renamed this import to avoid conflict
import MainPage from './components/MainPage';
import AreasOfExpertise from './components/InfoSection';
import ThreeScene from './components/ThreeScene';

const App = () => {
  return (
    <div className="App scroll-smooth">
      <MainPage />
      <Section />
      <AreasOfExpertise />      
      <ThreeScene />
      <Section5 />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
