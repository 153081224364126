import React, { useState, useEffect } from "react";
import MainLogo from "../asset/whitelogo.png";
import MainBack from "../asset/desktopmain.jpg";
import { HiMenu } from "react-icons/hi";
import { FaTimes } from 'react-icons/fa';

const MainPage = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div
      className="mainsec bg-cover bg-no-repeat h-[40vh] lg:h-[140vh] w-full flex gap-24 bg-[#FBF6E2]"
      style={{ backgroundImage: `url(${MainBack})` }}
    >
      {/* Navbar Container for larger screens */}
      <div
        className={`fixed transition-transform duration-300 ease-in-out bg-black ${
          isScrolled
            ? "top-10 left-1/2 transform -translate-x-1/2 flex-row h-auto w-[90%] md:w-[70%] lg:w-[40%] p-4 rounded-3xl"
            : "top-1/2 right-2 md:right-10 lg:right-20 transform -translate-y-1/2 flex-col justify-center h-[400px] md:h-[500px] lg:h-[600px] w-[80px] md:w-[90px] lg:w-[120px] p-3 md:p-4 lg:p-5 rounded-3xl"
        } flex items-center z-10 ${
          window.innerWidth < 768 ? "hidden" : "block"
        }`}
      >
        {/* Main Logo */}

        {/* Navbar Links */}
        <nav className={`flex flex-1 ${isScrolled ? 'flex-row space-x-4' : 'flex-col space-y-2'} items-center justify-center`}>
        <img
          src={MainLogo}
          alt="Main Logo"
          className={`w-10 h-10 md:w-12 md:h-12 lg:w-12 lg:h-10 ${isScrolled ? "mr-4 mb-0" : "mb-4"}`}
        />
          <a
            href="#Goals"
            className="text-white font-semibold md:text-lg no-underline py-2 w-full text-center"
          >
            Goals
          </a>
          <a
            href="#AboutUs"
            className="text-white font-semibold md:text-lg no-underline py-2 w-full text-center"
          >
            About Us
          </a>
          <a
            href="#Product"
            className="text-white font-semibold md:text-lg no-underline py-2 w-full text-center"
          >
            Product
          </a>
          <a
            href="#Contact"
            className="text-white font-semibold md:text-lg no-underline py-2 w-full text-center"
          >
            Contact
          </a>
        </nav>
      </div>

      {/* Hamburger Menu Icon for smaller screens */}
      {window.innerWidth < 768 && (
        <div
          className={`fixed flex items-center justify-center top-4 right-4 z-20 cursor-pointer bg-black h-16 w-16 z-1 transition-transform duration-300 bg-opacity-70 rounded-full ease-in-out ${isMenuOpen ? 'scale-90' : 'scale-100'}`}
          onClick={toggleMenu}
        >
          <HiMenu size={30} color="white"/>
        </div>
      )}

      {/* Mobile Menu */}
      <div
        className={`fixed top-0 right-0 h-full w-full bg-black p-4 flex flex-col space-y-4 z-20 duration-300 ease-in-out ${
          isMenuOpen ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0'
        }`}
        style={{ transform: isMenuOpen ? 'translateX(0)' : 'translateX(100%)' }}
      >
        <div
          className="fixed top-4 right-4 z-20 cursor-pointer transition-transform duration-300 ease-in-out"
          onClick={toggleMenu}
        >
          <FaTimes size={30} color="white" />
        </div>
        <div className="flex flex-col items-center justify-center gap-y-10 h-full p-8">
          <img
            src={MainLogo}
            alt="Main Logo"
            className="w-16 h-16 mb-8"
          />
          <a
            href="#Goals"
            className="text-white text-lg"
            onClick={toggleMenu}
          >
            Goals
          </a>
          <a
            href="#AboutUs"
            className="text-white text-lg"
            onClick={toggleMenu}
          >
            About Us
          </a>
          <a
            href="#Product"
            className="text-white text-lg"
            onClick={toggleMenu}
          >
            Product
          </a>
          <a
            href="#Contact"
            className="text-white text-lg"
            onClick={toggleMenu}
          >
            Contact
          </a>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
