import React from 'react';
import './HorizontalDivider.css';

const HorizontalDivider = () => {
  return (
    <div className="flex flex-row flex-wrap md:flex-nowrap">
      <div className="container">
        <div id="parallelogram"></div>
      </div>
      <div className="container2">
        <div id="leftskew"></div>
      </div>

      {/* Additional sections visible only on screens wider than 768px */}
      <div className="hidden md:flex flex-row ml-3">
        <div className="container">
          <div id="parallelogram"></div>
        </div>
        <div className="container2">
          <div id="leftskew"></div>
        </div>
      </div>

      <div className="hidden md:flex flex-row ml-3">
        <div className="container">
          <div id="parallelogram"></div>
        </div>
        <div className="container2">
          <div id="leftskew"></div>
        </div>
      </div>
    </div>
  );
};

export default HorizontalDivider;
